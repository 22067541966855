import React from "react";
import { AppContext } from "../../contexts/AppContext";
import { Link } from "react-router-dom";
import { FaWhatsapp, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";

function Footer() {
    const appContext = React.useContext(AppContext);
    const [userContact, setUserContact] = React.useState("");
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];

    const sendEmail = () => {
        // Send email mailtoLink
        const mailtoLink = `mailto:${appContext.information.email}?subject=Subscribe%20Request&body=Email%20Address%20or%20Mobile%20Number:%20${userContact}`;
        window.open(mailtoLink);
    }

    return (
        <footer class={`${theme.textNormal} ${theme.backgroundSecondary} body-font`}>
            <a href={appContext.footerData.whatsappCTA.link}
                target="_blank"
                rel="noopener noreferrer"
                class="fixed bottom-5 right-5 bg-green-500 hover:bg-green-700 text-white p-3 rounded-full transition duration-300 z-10">
                <FaWhatsapp class="w-10 h-10" />
            </a>
            <div class="container px-5 py-24 mx-auto">
                <div class="flex flex-wrap md:text-left text-center order-first">
                    {
                        appContext.footerData.footerSection.map((footerSection, index) => {
                            return (
                                <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                                    <h2 class={`title-font font-medium tracking-widest text-sm mb-3 ${theme.textFocus}`}>{footerSection.title}</h2>
                                    <nav class="list-none mb-10">
                                        {
                                            footerSection.links.map((link, index) => {
                                                return (
                                                    <li>
                                                        <Link class={`hover:${theme.textNormalHover}`} to={link.url} target="_blank">{link.title}</Link>
                                                    </li>
                                                );
                                            })
                                        }
                                    </nav>
                                </div>
                            );
                        })
                    }
                    <div class="lg:w-1/4 md:w-1/2 w-full px-4">
                        <h2 class={`title-font font-medium tracking-widest text-sm mb-3 ${theme.textFocus}`}>SUBSCRIBE</h2>
                        <div class="flex xl:flex-nowrap md:flex-nowrap lg:flex-wrap flex-wrap justify-center items-end md:justify-start">
                            <div class="relative w-40 sm:w-auto xl:mr-4 lg:mr-0 sm:mr-4 mr-2">
                                <label for="footer-field" class="leading-7 text-sm">Email Address or Mobile Number</label>
                                <input type="text" id="footer-field" name="footer-field" class={`w-full rounded border bg-opacity-40 border-gray-700 focus:bg-transparent focus:ring-2 focus:ring-brand-900 focus:border-brand-500 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${theme.backgroundSecondary}`} value={userContact} onChange={e => setUserContact(e.target.value)}/>
                            </div>
                            <button class="lg:mt-2 xl:mt-0 flex-shrink-0 inline-flex text-white bg-brand-500 border-0 py-2 px-6 focus:outline-none hover:bg-brand-600 rounded" onClick={sendEmail}>Subscribe</button>
                        </div>
                        <p class="text-sm mt-2 md:text-left text-center">Subscribe for our latest updates
                            <br class="lg:block hidden" /> with you email address
                        </p>
                    </div>
                </div>
            </div>
            <div class={`${theme.backgroundTertiary} bg-opacity-75`}>
                <div class="container px-5 py-6 mx-auto flex items-center sm:flex-row flex-col">
                    <Link class={`flex title-font font-medium items-center md:justify-start justify-center ${theme.textFocus}`} to="/">
                        <img src={appContext.logo} class="h-10" alt="logo"></img>
                    </Link>
                    <p class="text-sm sm:ml-6 sm:mt-0 mt-4">© 2023 {appContext.name} — {appContext.information.owner}
                    </p>
                    <p className="ml-3 flex items-center">
                        <IoLocationSharp className="w-5 h-5 mr-2" />
                        {appContext.information.address}
                    </p>
                    <span class="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
                        <a class="ml-3" href={appContext.socialMedia.find(socialMedia => socialMedia.platform === "twitter").url} target="_blank" aria-label="Twitter">
                            <FaTwitter class="w-5 h-5" />
                        </a>
                        <a class="ml-3" href={appContext.socialMedia.find(socialMedia => socialMedia.platform === "instagram").url} target="_blank" aria-label="Instagram">
                            <FaInstagram class="w-5 h-5"/>
                        </a>
                        <a class="ml-3" href={appContext.socialMedia.find(socialMedia => socialMedia.platform === "youtube").url} target="_blank" aria-label="Youtube">
                            <FaYoutube class="w-5 h-5"/>
                        </a>
                    </span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
