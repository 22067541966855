import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function Layout ({ toggleTheme, children }) {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return (
        <div className="layout">
            <Header toggleTheme={toggleTheme}/>
                {children}
            <Footer />
        </div>
    )
}

export default Layout;