import { createContext } from 'react';

export const ThemeContext = createContext();

export const ThemeContextData = {
    light: {
        textNormal: 'text-gray-600',
        textNormalHover: 'text-gray-800',
        textFocus: 'text-gray-900',
        background: 'bg-white',
        backgroundSecondary: 'bg-brand-200',
        backgroundTertiary: 'bg-brand-50',
        backgroundGradientVia: 'via-gray-200',
        backgroundGradientTo: 'to-gray-200',
        backgroundGradientFrom:'from-transparent',
        themeColour: 'brand'
    },
    dark: {
        textNormal: 'text-gray-400',
        textNormalHover: 'text-white',
        textFocus: 'text-white',
        background: 'bg-brand-800',
        backgroundSecondary: 'bg-brand-900',
        backgroundTertiary: 'bg-brand-700',
        backgroundGradientVia: 'via-brand-900',
        backgroundGradientTo: 'to-brand-900',
        backgroundGradientFrom:'from-transparent',
        themeColour: 'brand'
    },
};