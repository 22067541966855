import React, { useContext } from "react";
import { useRouteError } from "react-router-dom";
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";

const ErrorPage = () => {
  const themeContext = useContext(ThemeContext);
  const theme = ThemeContextData[themeContext];
  const error = useRouteError();

  return (
    <section className={`${theme.textNormal} ${theme.background} body-font`}>
      <div className="container px-5 py-24 mx-auto flex flex-col items-center">
        <div className={`lg:w-2/6 md:w-1/2 rounded-lg p-8 flex flex-col mt-10 ${theme.backgroundSecondary}`}>
          <h2 className="text-3xl font-medium title-font mb-4">Oops!</h2>
          <p className="leading-relaxed mb-6">Sorry, an unexpected error has occurred.</p>
          <p className={`italic mb-6 ${theme.textFocus}`}>{error?.statusText || error?.message}</p>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
