import { useNavigation } from "react-router-dom";
import React from 'react';
import {AppContext} from '../../contexts/AppContext';
import {ThemeContext, ThemeContextData} from '../../contexts/ThemeContext';

function ProductsPage() {
    const appContext = React.useContext(AppContext);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];  

    return (
        <section class={`${theme.textNormal} ${theme.background} body-font`}>
            <div class="container px-5 py-24 mx-auto">
                <div class="flex flex-col">
                    <div class={`h-1 ${theme.backgroundSecondary} rounded overflow-hidden`}>
                        <div class="w-32 h-full bg-brand-500"></div>
                    </div>
                    <div class="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
                        <h1 class={`sm:w-2/5 ${themeContext.textFocus} font-medium title-font text-2xl mb-2 sm:mb-0`}>{appContext.productsPageData.title}</h1>
                        <p class="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">{appContext.productsPageData.description}</p>
                    </div>
                </div>
                <div class="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
                    {
                        appContext.productsPageData.products.map(product => {
                            return (
                                <div class="p-4 md:w-1/3 sm:mb-0 mb-6">
                                    <div class="rounded-lg h-64 overflow-hidden">
                                        <img alt="content" class="object-cover object-center h-full w-full animate-fade-in" src={product.image} />
                                    </div>
                                    <h2 class="text-xl font-medium title-font text-white mt-5">{product.title}</h2>
                                    <p class="text-base leading-relaxed mt-2">{product.description}</p>
                                    <a class="text-brand-400 inline-flex items-center mt-3" href={product.link} target="_blank">{product.linkText}
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </a>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </section>
    );
}

export default ProductsPage;