import React, { useEffect } from "react";
import { AppContext } from "../../contexts/AppContext";
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";
import { NavLink, Link } from "react-router-dom";
import { Popover, Transition } from '@headlessui/react';
import Banner from "../banner/Banner";
import { Fragment } from 'react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { LIGHT_THEME } from "../../constants/ThemeConstants";
import { FaSun, FaMoon } from 'react-icons/fa';
import { UserButton, SignedIn, SignedOut } from "@clerk/clerk-react"

const DarkModeToggle = ({ toggleTheme }) => {
    const themeContext = React.useContext(ThemeContext);
    const isLightTheme = themeContext === LIGHT_THEME;
    const theme = ThemeContextData[themeContext];

    return (
        <button
            onClick={toggleTheme}
            className=""
        >
            <div className="flex items-center">
                <div className={`w-12 h-6 ${theme.backgroundTertiary} rounded-full p-1 mx-2 relative flex items-center`}>
                    <div className={`bg-white w-6 h-6 rounded-full shadow-lg transform transition-transform border ${isLightTheme ? 'ease-in-out -translate-x-1' : 'ease-in-out translate-x-full'}`}>
                        {isLightTheme ? (
                            <FaSun className="text-yellow-500 absolute left-1 top-1/2 transform -translate-y-1/2" />
                        ) : (
                            <FaMoon className="text-gray-500 absolute right-1 top-1/2 transform -translate-y-1/2" />
                        )}
                    </div>
                </div>
            </div>
        </button>
    );
};

function Header({toggleTheme}) {
    const appContext = React.useContext(AppContext);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];

    return (
        <>
            <Banner />
            <div className={`${theme.textNormal} ${theme.backgroundSecondary} body-font`}>
                <Popover>
                    <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                        <div className="items-center justify-between w-full md:w-auto">
                            <div className="hidden md:block">
                            <NavLink className={`flex title-font font-medium items-center ${theme.textFocus} mb-4 md:mb-0`} to="/">
                                <span className="sr-only">Workflow</span>
                                <img src={appContext.logo} class="h-14" alt="logo"></img>
                            </NavLink>
                            </div>
                            <div className="flex items-center justify-between md:hidden">
                                <div className="flex items-center">
                                    <Popover.Button className={`bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500`}>
                                        <span className="sr-only">Open main menu</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                                <div className="flex items-center">
                                    <img src={appContext.logo} class="h-14" alt="logo"></img>
                                </div>
                                <div className="flex items-center">
                                    <DarkModeToggle toggleTheme={toggleTheme} />
                                </div>
                            </div>
                        </div>
                        <nav class="md:ml-auto flex flex-wrap items-center text-base justify-center hidden md:block">
                            <NavLink className={({ isActive, isPending }) => `mr-5 hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/" >Home</NavLink>
                            <NavLink className={({ isActive, isPending }) => `mr-5 hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/about-us">About Us</NavLink>
                            <NavLink className={({ isActive, isPending }) => `mr-5 hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/contact-us">Contact Us</NavLink>
                            <NavLink className={({ isActive, isPending }) => `mr-5 hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/programs">Programs</NavLink>
                            <NavLink className={({ isActive, isPending }) => `mr-5 hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/products">Products</NavLink>
                            <SignedOut>
                                <NavLink to="/sign-in" className={({ isActive, isPending }) => `mr-5 hover:${theme.textFocus} ${isActive?theme.textFocus:""}`}>Sign In</NavLink>
                            </SignedOut>
                        </nav>
                        <div className="hidden md:block mr-5">
                            <SignedIn>
                                <UserButton afterSignOutUrl='/sign-in'/>
                            </SignedIn>
                        </div>
                        <div className="hidden md:block">
                            <DarkModeToggle toggleTheme={toggleTheme} />
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-150 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right"
                        >
                            {({ close }) => (
                            <div className={`rounded-lg shadow-md shadow-brand-500 body-font ring-1 ring-black ring-opacity-5 overflow-hidden ${theme.textNormal} ${theme.background}`}>
                                <div className="px-5 pt-4 flex items-center justify-between">
                                    <div className="flex items-center">
                                        <SignedIn>
                                            <UserButton afterSignOutUrl='/sign-in'/>
                                        </SignedIn>
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500">
                                            <span className="sr-only">Close main menu</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true" />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="px-2 pt-2 pb-3 space-y-1">
                                    <NavLink className={({ isActive, isPending }) => `mr-5 block px-3 py-2 rounded-md text-base font-medium hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/" onClick={close}>Home</NavLink>
                                    <NavLink className={({ isActive, isPending }) => `mr-5 block px-3 py-2 rounded-md text-base font-medium hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/about-us" onClick={close}>About Us</NavLink>
                                    <NavLink className={({ isActive, isPending }) => `mr-5 block px-3 py-2 rounded-md text-base font-medium hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/contact-us" onClick={close}>Contact Us</NavLink>
                                    <NavLink className={({ isActive, isPending }) => `mr-5 block px-3 py-2 rounded-md text-base font-medium hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/programs" onClick={close}>Programs</NavLink>
                                    <NavLink className={({ isActive, isPending }) => `mr-5 block px-3 py-2 rounded-md text-base font-medium hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} to="/products" onClick={close}>Products</NavLink>
                                    <SignedOut>
                                        <NavLink to="/sign-in" className={({ isActive, isPending }) => `mr-5 block px-3 py-2 rounded-md text-base font-medium hover:${theme.textFocus} ${isActive?theme.textFocus:""}`} onClick={close}>Sign In</NavLink>
                                    </SignedOut>
                                </div>
                            </div>
                            )}
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </div>
        </>
    );
}

export default Header;
