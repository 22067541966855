import React from 'react';
import Slider from 'react-slick';
import { AppContext } from '../../contexts/AppContext';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ThemeContext, ThemeContextData } from '../../contexts/ThemeContext';
import "./HomePage.css"
import { TestimonialComponent } from "../../components/TestimonialsComponent";

function HomePage() {
    const appContext = React.useContext(AppContext);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];
    const { carouselImages, otherSections, mainSection } = appContext.homePageData;
    const [isFlipped, setFlipped] = React.useState(false);
 
    const handleFlip = () => {
        setFlipped(!isFlipped);
    };

    const carouselSettings = {
        infinite: true,
        speed: 2000,
        cssEase: "linear",
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    };

    return (
        <div class={`${theme.textNormal} ${theme.background} body-font relative`}>
            {/* Slick Carousel */}
            <Slider {...carouselSettings} className="sm:-mx-10px">
                {carouselImages.map((image, index) => (
                    <div key={index} className="relative">
                        <img className="w-full h-auto" src={image.url} alt={image.alt} />
                        <div className="carousel-caption absolute md:bottom-60 bottom-10 left-0 right-0 text-white text-center p-4">
                            <h2 className="text-3xl mb-2">{image.caption}</h2>
                            <Link to={image.ctaButton.link} className="carousel-button mx-auto text-white bg-brand-500 border-0 focus:outline-none hover:bg-brand-600 rounded text-sm py-1 px-1 sm:text-base md:py-2 md:px-8 md:text-lg">
                                {image.ctaButton.text}
                            </Link>
                        </div>
                    </div>
                ))}
            </Slider>
            {
                otherSections.map((section, index) => {
                    if (section.type === "mainSection") {
                        return (
                            <section className={`relative bg-cover bg-center py-20 ${index % 2 === 1 ? "" : theme.backgroundSecondary}`} style={{ backgroundImage: `url(${section.image.url})` }}>
                                <div className="container mx-auto flex px-10 py-20 items-center justify-center flex-col">
                                    <h2 className={`text-5xl ${theme.textFocus} font-bold mb-8 text-center`}>{section.title}</h2>
                                    <p className="leading-relaxed text-xl mb-8 text-center">{section.description}</p>
                                    <Link to={section.ctaButton.link} className="mx-auto text-white bg-brand-500 border-0 py-3 px-10 focus:outline-none hover:bg-brand-600 rounded text-lg">
                                        {section.ctaButton.text}
                                    </Link>
                                </div>
                            </section>)
                    } 
                    else if(section.type === "cards-flip"){
                        return (
                        <section key={index} className={`py-8 sm:py-16 ${index % 2 === 1 ? "" : theme.backgroundSecondary}`}>
                                <h2 className={`text-2xl sm:text-3xl md:text-4xl ${theme.textFocus} font-bold pb-8 mb-4 sm:mb-6 md:mb-8 text-center`}>
                                    {section.title}
                                </h2>
                                <p className="leading-relaxed text-lg sm:text-xl mb-4 sm:mb-6 md:mb-8 text-center">
                                    {section.description}
                                </p>
                                <div className="container mx-auto flex flex-wrap justify-center h-full ">
                                    {section.cards.map((card, cardIndex) => (
                                        <div className={` flip-card ${ isFlipped ? "flipped" : ""}`} onClick={() => handleFlip(!isFlipped)}>
                                            <div key={cardIndex} className="rounded-xl flip-card-inner button-flip w-full ring-4 ring-brand-300 ring-opacity-50 sm:w-1/2 md:w-1/3 lg:w-1/4 " style={{ backgroundImage: `url(${card.image.url})`,backgroundRepeat: `no-repeat`, backgroundSize: `cover`}}>
                                                <div className={`w-full flip-card-front rounded-lg shadow-lg flex flex-col items-center ring-4 ring-brand-300 ring-opacity-50 h-full ${card.ctaButton ? "h-80" : "h-56"}`}>
                                                    <div className="p-6 pt-10 text-center flex flex-col justify-center items-center">
                                                        <div className="text-2xl p-2 font-bold mb-2 text-white">{card.title}</div>
                                                    </div>
                                                    </div>
                                                <div className={` flip-card-back rounded-lg shadow-lg flex flex-col items-center ring-4 ring-brand-300 ring-opacity-50 h-full ${card.ctaButton ? "h-80" : "h-56"}`}>
                                                    <div className="p-6 pt-10 text-center flex flex-col justify-center items-center">
                                                  
                                                        <p className="text-base p-2 text-white">{card.description}</p>
                                                        {card.ctaButton &&
                                                            <Link to={card.ctaButton.link} className="mx-auto mt-4 text-white bg-brand-500 border-0 py-2 px-4 focus:outline-none hover:bg-brand-600 rounded text-lg">
                                                                {card.ctaButton.text}
                                                            </Link>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        );
                    }
                    else if (section.type === "cards") {
                        return (
                            <section key={index} className={`py-8 sm:py-16 ${index % 2 === 1 ? "" : theme.backgroundSecondary}`}>
                                <h2 className={`text-2xl sm:text-3xl md:text-4xl ${theme.textFocus} font-bold pb-8 mb-4 sm:mb-6 md:mb-8 text-center`}>
                                    {section.title}
                                </h2>
                                <p className="leading-relaxed text-lg sm:text-xl mb-4 sm:mb-6 md:mb-8 text-center">
                                    {section.description}
                                </p>
                                <div className="container mx-auto flex flex-wrap justify-center">
                                    {section.cards.map((card, cardIndex) => (
                                        <div key={cardIndex} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-4 pt-12 sm:pt-0">
                                            <div className={`rounded-lg shadow-lg flex flex-col items-center ring-4 ring-brand-300 ring-opacity-50 ${card.ctaButton ? "h-80" : "h-56"}`}>
                                                <div className="relative w-24 h-24 flex justify-center items-center -mt-12">
                                                    <div className="rounded-full overflow-hidden border-4 border-white shadow-md bg-white w-24 h-24">
                                                        <img className="w-full h-full object-cover" src={card.image.url} alt={card.image.alt} />
                                                    </div>
                                                </div>
                                                <div className="p-6 text-center flex flex-col justify-center items-center">
                                                    <h3 className="text-lg sm:text-xl font-bold mb-2">{card.title}</h3>
                                                    <p className="text-base">{card.description}</p>
                                                    {card.ctaButton &&
                                                        <Link to={card.ctaButton.link} className="mx-auto mt-4 text-white bg-brand-500 border-0 py-2 px-4 focus:outline-none hover:bg-brand-600 rounded text-lg">
                                                            {card.ctaButton.text}
                                                        </Link>}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        );
                    } else if (section.type === "testimony") {
                        return (
                            <section key={index} className={`py-8 sm:py-16 ${index % 2 === 1 ?  "" : theme.backgroundSecondary}`}>
                                <h2 className={`text-2xl sm:text-3xl md:text-4xl ${theme.textFocus} font-bold mb-4 text-center`}>
                                    {section.title}
                                </h2>
                                <p className="leading-relaxed text-lg sm:text-xl mb-4 text-center">
                                    {section.description}
                                </p>
                                {TestimonialComponent(section.testimonials, null, index % 2 === 1 ? "" : theme.backgroundSecondary)}
                            </section>
                        )
                    } else {
                        return (
                            <section className={`body-font relative overflow-hidden ${index % 2 === 1 ? "" : theme.backgroundSecondary}`}>
                                <div className="container mx-auto flex px-10 py-10 items-center justify-center flex-col">
                                    <h2 className={`text-4xl ${theme.textFocus} font-bold mb-8 text-center`}>{section.title}</h2>
                                    <div className="flex flex-wrap items-center w-full">
                                        <div className={`w-full sm:w-1/3 p-4 ${index % 2 ? "block sm:hidden" : ""}`}>
                                            <img className="w-full h-full object-cover rounded-lg" src={section.image.url} alt={section.image.alt} />
                                        </div>
                                        <div className="w-full sm:w-2/3 p-4 text-center">
                                            <p className="leading-relaxed text-base mb-4">{section.description}</p>
                                            <Link to={section.ctaButton.link} className="mx-auto mt-4 text-white bg-brand-500 border-0 py-2 px-8 focus:outline-none hover:bg-brand-600 rounded text-lg">
                                                {section.ctaButton.text}
                                            </Link>
                                        </div>
                                        <div className={`w-full sm:w-1/3 p-4 ${!(index % 2) ? "hidden" : "hidden sm:block"}`}>
                                            <img className="w-full h-64 object-cover rounded-lg" src={section.image.url} alt={section.image.alt} />
                                        </div>
                                    </div>
                                </div>
                            </section>)
                    }
                })
            }
        </div>
    );
}

export default HomePage;
