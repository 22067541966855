import React from 'react';
import { AppContext } from '../../contexts/AppContext';
import Parser from 'html-react-parser';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";

export default function AboutUsPage() {
    const appContext = React.useContext(AppContext);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];
    const sliderSettings = {
        lazyLoad: true,
        centerMode: true,
        infinite: true,
        speed: 2000,
        cssEase: "linear",
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    return (
        <>
            { <section class={`${theme.textNormal} ${theme.background} body-font`}>
            <div class="container px-5 py-24 mx-auto flex flex-col">
                    <div class="lg:w-full mx-auto">
                    <div className="rounded-lg overflow-hidden" style={{ paddingBottom: '37%', position: 'relative' }}>
    <img alt="content" className="absolute inset-0 object-cover object-center w-full h-full" src={appContext.aboutUsPageData.detailsSection.image} />
</div>
                        <div class="flex flex-col sm:flex-row mt-10 items-center justify-center">
                        </div>
                        <div class="flex flex-col sm:flex-row items-center justify-center px-5 py-5 ">
                        <div class="sm:w-2/3 sm:pr-8 sm:py-8 sm:border-r border-gray-800 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                        <h4 class={`title-font sm:text-4xl text-3xl mb-4 font-medium ${theme.textFocus}`}>{appContext.aboutUsPageData.detailsSection.title}</h4>
                                <p class="leading-relaxed text-lg mb-4">{Parser(appContext.aboutUsPageData.detailsSection.description)}</p>
                                <h4 class={`title-font sm:text-4xl text-3xl mb-4 font-medium ${theme.textFocus}`}>{appContext.aboutUsPageData.detailsSection.vision.title}</h4>
                                <p class="leading-relaxed text-lg mb-4">{Parser(appContext.aboutUsPageData.detailsSection.vision.description)}</p>
                                <h4 class={`title-font sm:text-4xl text-3xl mb-4 font-medium ${theme.textFocus}`}>{appContext.aboutUsPageData.detailsSection.mission.title}</h4>
                                {
                                    appContext.aboutUsPageData.detailsSection.mission.description.map((bulletPoint) => {
                                        return (
                                            <li class="leading-relaxed text-lg mb-4">{bulletPoint}</li>
                                            );
                                        })
                                    
                                }
                            </div>
                            <div class="sm:w-1/3 text-center sm:pl-8 sm:py-8">
                                <div class={`w-20 h-20 rounded-full inline-flex items-center justify-center ${theme.backgroundSecondary} text-gray-600`}>
                                    <img alt="content" class="rounded-full" src={appContext.aboutUsPageData.detailsSection.owner.image} />
                                </div>
                                <div class="flex flex-col items-center text-center justify-center">
                                    <h2 class={`font-medium title-font mt-4 ${theme.textFocus} text-lg`}>{appContext.aboutUsPageData.detailsSection.owner.name}</h2>
                                    <div class="w-12 h-1 bg-brand-500 rounded mt-2 mb-4"></div>
                                    <p class={`text-base ${theme.textNormal}`}>{appContext.aboutUsPageData.detailsSection.owner.description}</p>
                                </div>
                                <Link class="text-brand-400 hover:text-brand-500 inline-flex items-center" to={appContext.aboutUsPageData.detailsSection.owner.anchorLink}>{appContext.aboutUsPageData.detailsSection.owner.anchorText}
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                            <path d="M5 12h14M12 5l7 7-7 7"></path>
                                        </svg>
                                    </Link>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>
            </section> }
            <section class={`${theme.textNormal} body-font ${theme.background} ${appContext.aboutUsPageData.qualificationsSection.isHidden?"hidden":""}`}>
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                        <h1 class={`sm:text-3xl text-2xl font-medium title-font mb-2 ${theme.textFocus}`}>{appContext.aboutUsPageData.qualificationsSection.title}</h1>
                        <div class="h-1 w-40 bg-brand-500 rounded"></div>
                        <p class="lg:w-1/2 w-full leading-relaxed text-opacity-80">{appContext.aboutUsPageData.qualificationsSection.description}</p>
                    </div>
                    <div class="flex flex-wrap -m-4">
                        {
                            appContext.aboutUsPageData.qualificationsSection.qualifications.map(qualification => {
                                return (
                                    <div class="xl:w-1/3 md:w-1/2 p-4">
                                        <div class="border hover:border-2 border-gray-700 border-opacity-75 p-6 rounded-lg">
                                            <div class={`w-10 h-10 inline-flex items-center justify-center rounded-full ${theme.backgroundSecondary} text-brand-400 mb-4`}>
                                                <img alt="content" class="rounded-full" src={qualification.image} />
                                            </div>
                                            <h2 class={`text-lg ${theme.textFocus} font-medium title-font mb-2`}>{qualification.title}</h2>
                                            <p class="leading-relaxed text-base">{qualification.description}</p>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </section>
            <section class={`${theme.textNormal} body-font ${theme.background}`}>
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-wrap w-full mb-20 flex-col items-center text-center">
                        <h1 class={`sm:text-3xl text-2xl font-medium title-font mb-2 ${theme.textFocus}`}>{appContext.aboutUsPageData.recognitionSection.title}</h1>
                        <div class="h-1 w-20 bg-brand-500 rounded"></div>
                        <p class="lg:w-1/2 w-full leading-relaxed text-opacity-80">{appContext.aboutUsPageData.recognitionSection.description}</p>
                    </div>
                    <div class="flex-wrap -m-4 items-center justify-center">
                        <Slider {...sliderSettings}>
                            {
                                appContext.aboutUsPageData.recognitionSection.recognitions.map(recognition => {
                                    return (
                                        <div class="xl:w-1/4 md:w-1/2 p-4">
                                            <div class={`${theme.backgroundSecondary} bg-opacity-40 p-6 rounded-lg hover:border border-gray-700 border-opacity-75`}>
                                                <img class="h-40 rounded w-full object-cover object-center mb-6" src={recognition.image} alt="content" />
                                                <h3 class="tracking-widest text-brand-400 text-xs font-medium title-font">{recognition.subtitle}</h3>
                                                <h2 class={`text-lg ${theme.textFocus} font-medium title-font mb-4`}>{recognition.title}</h2>
                                                <p class="leading-relaxed text-base">{recognition.description}</p>
                                                <a class="text-brand-400 hover:text-brand-500 inline-flex items-center mt-3" href={recognition.link} target="_blank">{recognition.linkText}
                                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </section>
        </>
    );
}