import React from "react";
import { ThemeContext, ThemeContextData } from "../contexts/ThemeContext";

export function TestimonialComponent(testimonials, title, background) {
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];

    return <section class={`${theme.textNormal} ${background ? background : theme.background} body-font`}>
        <div class="container px-5 pb-24 mx-auto">
            {title && <h1 class={`text-3xl font-medium title-font ${theme.textFocus} mb-12 text-center`}>{title}</h1>}
            <div class="flex flex-wrap -m-4">
                {testimonials.map((testimonial, index) => {
                    return (
                        <div class="p-4 md:w-1/2 w-full">
                            <div class={`h-full ${theme.backgroundSecondary} bg-opacity-40 p-4 sm:p-6 md:p-8 rounded ring-4 ring-brand-300 ring-opacity-50`}>
                                <div class="flex flex-col md:flex-row items-start space-y-4 md:space-y-0 md:space-x-4">
                                    {!testimonial.videoTestimony && (
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="block w-5 h-5 text-gray-500 mb-4 md:mb-0" viewBox="0 0 975.036 975.036">
                                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                                        </svg>
                                    )}
                                    {testimonial.videoTestimony && (
                                        <iframe class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4" height="315" src={`https://www.youtube.com/embed/${testimonial.videoTestimony.videoId}`} title="Sreedevi Client Testimonial for GRIPIT.FIT By Srushti Shah (Calisthenics Online Live Classes)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                    )}
                                    <div class="flex-1">
                                        <p class="leading-relaxed mb-6">{testimonial.testimony}</p>
                                        <a class="inline-flex items-center pt-4">
                                            <img alt="testimonial" src={testimonial.user.profileImage} class="w-12 h-12 rounded-full flex-shrink-0 object-cover object-center" />
                                            <span class="flex-grow flex flex-col pl-4">
                                                <span class={`title-font font-medium ${theme.textFocus}`}>{testimonial.user.firstName} {testimonial.user.lastName}</span>
                                                <span class="text-gray-500 text-sm">{testimonial.user.designation}</span>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    </section>;
}