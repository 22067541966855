import './App.css';
import Header from '../components/header/Header';
import { AppContext, AppContextData } from '../contexts/AppContext';
import { ThemeContext, ThemeContextData } from '../contexts/ThemeContext';
import Layout from '../components/layout/Layout';
import {
  createBrowserRouter,
  Router,
  RouterProvider,
  useNavigation
} from "react-router-dom";
import React from 'react';
import ErrorPage from '../pages/errorPage/ErrorPage';
import HomePage from '../pages/homePage/HomePage';
import ContactUsPage from '../pages/contactUsPage/ContactUsPage';
import ProgramsPage from '../pages/programsPage/ProgramsPage';
import AboutUsPage from '../pages/aboutUsPage/AboutUsPage';
import ProductsPage from '../pages/productsPage/ProductsPage';
import TermsAndConditionsPage from '../pages/termsAndConditionsPage/TermsAndConditionsPage';
import ProgramPage from '../pages/programsPage/ProgramPage';
import SignInPage from '../pages/authPage/SignInPage';
import SignUpPage from '../pages/authPage/SignUpPage';
import { LIGHT_THEME, DARK_THEME } from '../constants/ThemeConstants';
import { dark, shadesOfBlue, light } from '@clerk/themes';
import { ClerkProvider } from '@clerk/clerk-react'

// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
 
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

function App() {
  const [theme, setTheme] = React.useState(localStorage.getItem("theme") || LIGHT_THEME);
  const toggleTheme = () => {
    const newTheme = theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME;
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  }

  const LayoutWrapper = ({children}) => {
    const navigation = useNavigation();
    return (
      <Layout toggleTheme={toggleTheme}>
        {navigation.state === "loading" && <h1> spin </h1>}
        {navigation.state === "idle" && children}
      </Layout>
    );
  }

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LayoutWrapper><HomePage /></LayoutWrapper>,
      errorElement: <LayoutWrapper><ErrorPage /></LayoutWrapper>,
    },
    {
      path: "/contact-us",
      element: <LayoutWrapper><ContactUsPage /></LayoutWrapper>
    },
    {
      path: "/programs",
      element: <LayoutWrapper><ProgramsPage /></LayoutWrapper>
    },
    {
      path: "/programs/:programDisplayId/:variantDisplayId",
      element: <LayoutWrapper><ProgramPage /></LayoutWrapper>
    },
    {
      path: "/about-us",
      element: <LayoutWrapper><AboutUsPage /></LayoutWrapper>
    },
    {
      path: "/products",
      element: <LayoutWrapper><ProductsPage /></LayoutWrapper>
    },
    {
      path: "/terms-and-conditions",
      element: <LayoutWrapper> <TermsAndConditionsPage/></LayoutWrapper>
    },
    {
      path: "/error",
      element: <LayoutWrapper><ErrorPage /></LayoutWrapper>
    },
    { 
      path: "/sign-in", 
      element: <LayoutWrapper><SignInPage /></LayoutWrapper>
    },
    { 
      path: "/sign-up", 
      element: <LayoutWrapper><SignUpPage /></LayoutWrapper>
    },
  ]);

  return (
    <React.StrictMode>
      <ClerkProvider 
        publishableKey={PUBLISHABLE_KEY}
        appearance={{
          baseTheme: theme === LIGHT_THEME ? light : [dark, shadesOfBlue],
          variables: { colorPrimary: '#3F6BAA' }
        }} 
      >
        <AppContext.Provider value={AppContextData}>
          <ThemeContext.Provider value={theme}>
            <RouterProvider router={router} />
          </ThemeContext.Provider>
        </AppContext.Provider>
      </ClerkProvider>
    </React.StrictMode>
  );
}

export default App;
