import { SignUp } from "@clerk/clerk-react"
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";
import React from "react";

export default function SignUpPage() {
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];

  return (
    <div className={`flex items-center justify-center mx-auto my-auto p-8 ${theme.background}`}>
        <SignUp path="/sign-up" routing="virtual" signInUrl="/sign-in" />
    </div>
  )
}