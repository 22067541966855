import React, { useEffect } from "react";
import { AppContext } from "../../contexts/AppContext";
import { Link } from "react-router-dom";
import { TestimonialComponent } from "../../components/TestimonialsComponent";
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";
import { useSearchParams } from 'react-router-dom';

export default function ProgramsPage() {
    const SEARCH_PARAMS_TAG_KEY = "tag";
    const appContext = React.useContext(AppContext);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];
    const [searchParams, setSearchParams] = useSearchParams();
    const [currentTag, setCurrentTag] = React.useState(searchParams.get(SEARCH_PARAMS_TAG_KEY) ? searchParams.get(SEARCH_PARAMS_TAG_KEY) : appContext.programsPageData.programsSection.tags[0]?.id);
    const MAX_NUMBER_OF_BULLET_POINTS = 2;
    const BULLET_POINTS_CONTENT_HEIGHT = 44 * MAX_NUMBER_OF_BULLET_POINTS;

    useEffect(() => {
        setSearchParams({ [SEARCH_PARAMS_TAG_KEY]: currentTag });
    }, [currentTag]);

    return (
        <>
            <section class={`${theme.background} ${theme.textNormal} body-font overflow-hidden`}>
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-col text-center w-full mb-10 px-4 md:px-8 lg:px-16">
                        <h1 class={`sm:text-4xl text-3xl font-medium title-font mb-2 ${theme.textFocus}`}>{appContext.programsPageData.programsSection.title}</h1>
                        <p class="lg:w-2/3 mx-auto leading-relaxed text-base md:text-lg lg:text-xl">{appContext.programsPageData.programsSection.description}</p>
                        <div class={`flex mx-auto border-2 border-brand-500 rounded overflow-hidden mt-6 font-bold ${appContext.programsPageData.programsSection.hideTagsFilter ? "hidden" : ""}`}>
                            {
                                appContext.programsPageData.programsSection.tags.map((tag, index) => {
                                    return (
                                        <button key={index} class={`py-1 px-2 sm:px-4 ${tag.id == currentTag ? `bg-brand-500 text-white` : `${theme.textNormal}`} focus:outline-none`} onClick={() => setCurrentTag(tag.id)}>{tag.title}</button>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div class="flex flex-wrap -m-4 justify-center items-center">
                        {
                            appContext.programsPageData.programsSection.programs.filter(program => appContext.programsPageData.programsSection.hideTagsFilter || program.tags.includes(currentTag))
                                .filter(program => !program.isArchived)
                                .map((program, index) => {
                                    return {
                                        ...program,
                                        variants: program.variants.filter(variant => variant.tags ? variant.tags.includes(currentTag) : true)
                                    }
                                })
                                .map((program, productIndex) => {
                                    const variant = program.variants[0];
                                    const maxDiscount = program.variants.reduce((maxDiscount, variant) => Math.max(maxDiscount, variant.discount), 0);
                                    const maxPrice = program.variants.reduce((maxPrice, variant) => Math.max(maxPrice, variant.price), 0);
                                    const minPrice = program.variants.reduce((minPrice, variant) => Math.min(minPrice, variant.price), maxPrice);

                                    return (
                                        <div class={`p-4 xl:w-1/4 md:w-1/2 w-full`}>
                                            <div class={`h-full p-6 rounded-lg border-2 border-brand-300 hover:border-brand-500 flex flex-col relative overflow-hidden ${theme.backgroundSecondary}`}>
                                                {
                                                    maxDiscount > 0 && <span key={`${currentTag}_${productIndex}_type`} class={`bg-brand-500 ${theme.textFocus} px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl animate-fade-in`}> Upto {maxDiscount}% Discount </span>
                                                }
                                                <h2 class={`text-lg tracking-widest title-font mb-1 font-medium`}>
                                                    {
                                                        variant.noPriceDisplayText ? (<span></span>) :
                                                            (<span>₹{maxPrice === minPrice ? minPrice : `${minPrice}-${maxPrice}`}</span>)
                                                    }
                                                    {
                                                        variant.auxiliaryData?.costSuffix && <span class="ml-1">{variant.auxiliaryData.costSuffix}</span>
                                                    }
                                                </h2>
                                                <h1 key={`${currentTag}_${productIndex}_cost`} class={`text-3xl ${theme.textFocus} leading-none flex items-center pb-4 mb-4 border-b border-gray-800 animate-fade-in font-bold`}>
                                                    {program.name}
                                                </h1>
                                                {
                                                    program.images?.length > 0 && <img src={program.images[0]} alt="content" class="w-full h-full object-cover object-center mb-6 p-0 rounded-md" />
                                                }
                                                <div class="flex items-center flex-wrap" style={{ height: `${BULLET_POINTS_CONTENT_HEIGHT}px` }}>
                                                    {
                                                        program.summaryBulletPoints.slice(0, MAX_NUMBER_OF_BULLET_POINTS).map((bulletPoint, bulletPointIndex) => {
                                                            return (
                                                                <p class="flex items-center mb-2">
                                                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center rounded-full flex-shrink-0">
                                                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                                                            <path d="M20 6L9 17l-5-5"></path>
                                                                        </svg>
                                                                    </span> {bulletPoint}
                                                                </p>
                                                            );
                                                        })
                                                    }
                                                </div>
                                                <Link class="flex items-center mt-auto text-white bg-brand-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-brand-600 rounded font-bold my-4" to={`/programs/${program.displayId}/${variant.displayId}`}>View Details
                                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                    </svg>
                                                </Link>
                                                <a class="flex items-center mt-auto text-white bg-brand-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-brand-600 rounded font-bold" href={`${program.auxiliaryData.registrationLink}`}>Register Now
                                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                                    </svg>
                                                </a>
                                                <p class="text-xs mt-3">{program.descriptionSummary}</p>
                                            </div>
                                        </div>
                                    );
                                })
                        }
                    </div>
                </div>
            </section>
            {TestimonialComponent(appContext.programsPageData.testimonialSection.testimonials.filter(testimonial => testimonial.tags.includes(currentTag)), appContext.programsPageData.testimonialSection.title)}
        </>
    );
}