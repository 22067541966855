import React from "react";
import { AppContext } from "../../contexts/AppContext";
import { SiGmail } from "react-icons/si";
import { FaInstagram, FaWhatsapp, FaTwitter } from "react-icons/fa";
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";

export default function ContactUsPage() {
  const appContext = React.useContext(AppContext);
  const themeContext = React.useContext(ThemeContext);
  const theme = ThemeContextData[themeContext];
  const [formData, setFormData] = React.useState({
    name: "",
    contactNumber: "",
    message: ""
  });

  const updateFormData = (key, e) => {
    // Validate contact number is valid
    if(key === "contactNumber"){
      // Check if there are any alphabets
      if(e.target.value != "" && !e.target.value.match(/^[0-9]+$/) || e.target.value.length > 10){
        return;
      }
    }
    setFormData({
      ...formData,
      [key]: e.target.value
    });
  }

  const sendEmail = () => {
    // Send email mailtoLink
    const mailtoLink = `mailto:${appContext.information.email}?subject=Contact%20Us%20Request&body=Name:%20${formData.name}%0AContact%20Number:%20${formData.contactNumber}%0AMessage:%20${formData.message}`;
    window.open(mailtoLink);
  }

  return (
    <section class={`${theme.textNormal} ${theme.background} body-font relative`}>
      <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-12">
          <h1 class={`sm:text-3xl text-2xl font-medium title-font mb-4 ${theme.textFocus}`}>{appContext.contactUsPageData.title}</h1>
          <p class="lg:w-2/3 mx-auto leading-relaxed text-base">{appContext.contactUsPageData.description}</p>
        </div>
        <div class="lg:w-1/2 md:w-2/3 mx-auto">
          <div class="flex flex-wrap -m-2">
            <div class="p-2 w-1/2">
              <div class="relative">
                <label for="name" class="leading-7 text-sm">Name</label>
                <input type="text" id="name" name="name" class={`w-full ${theme.backgroundSecondary} bg-opacity-40 rounded border border-gray-700 focus:border-brand-500 focus:${theme.background} focus:ring-2 focus:ring-brand-900 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`} value={formData.name} onChange={e => updateFormData("name",e)}/>
              </div>
            </div>
            <div class="p-2 w-1/2">
              <div class="relative">
                <label for="email" class="leading-7 text-sm">Your Contact Number</label>
                <input type="email" id="email" name="email" class={`w-full ${theme.backgroundSecondary} bg-opacity-40 rounded border border-gray-700 focus:border-brand-500 focus:${theme.background} focus:ring-2 focus:ring-brand-900 text-base outline-none py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`} value={formData.contactNumber} onChange={e => updateFormData("contactNumber", e)}/>
              </div>
            </div>
            <div class="p-2 w-full">
              <div class="relative">
                <label for="message" class="leading-7 text-sm">Message</label>
                <textarea id="message" name="message" class={`w-full ${theme.backgroundSecondary} bg-opacity-40 rounded border border-gray-700 focus:border-brand-500 focus:${theme.background} focus:ring-2 focus:ring-brand-900 h-32 text-base outline-none py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out`} value={formData.message} onChange={e => updateFormData("message", e)}></textarea>
              </div>
            </div>
            <div class="p-2 w-full">
              <button class="flex mx-auto text-white bg-brand-500 border-0 py-2 px-8 focus:outline-none hover:bg-brand-600 rounded text-lg" onClick={sendEmail}>Send Message</button>
            </div>
            <div class="p-2 w-full pt-8 mt-8 border-t border-gray-800 text-center flex flex-wrap justify-center">
              {
                  appContext.socialMedia.map((socialMedia, index) => {
                    switch(socialMedia.platform) {
                      case "instagram":
                        return (
                          <div className="p-4 md:w-1/3 w-full flex items-center justify-center">
                            <a href={socialMedia.url} target="_blank" className="flex items-center">
                              <FaInstagram className="mr-2 text-brand-400" />
                              {socialMedia.text}
                            </a>
                          </div>
                        );
                      case "email":
                        return (
                          <div className="p-4 md:w-1/3 w-full flex items-center justify-center">
                            <a href={socialMedia.url} target="_blank" className="flex items-center">
                              <SiGmail class="mr-2 text-brand-400" /> {socialMedia.text}
                            </a>
                          </div>
                        );
                      case "whatsapp":
                        return (
                          <div className="p-4 md:w-1/3 w-full flex items-center justify-center">
                            <a href={socialMedia.url} target="_blank" className="flex items-center">
                              <FaWhatsapp class="mr-2 text-brand-400" /> {socialMedia.text}
                            </a>
                          </div>
                        );
                      case "twitter":
                        return (
                          <div className="p-4 md:w-1/3 w-full flex items-center justify-center">
                            <a href={socialMedia.url} target="_blank" className="flex items-center">
                              <FaTwitter class="mr-2 text-brand-400" /> {socialMedia.text}
                            </a>
                          </div>
                        );
                    }
                  })
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}