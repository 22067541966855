import React, { useState } from 'react';
import { ThemeContext, ThemeContextData } from '../contexts/ThemeContext';

export const FAQComponent = ( faqs, title ) => {
    const [activeIndex, setActiveIndex] = useState(null);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];  

    const toggleFAQ = (index) => {
      setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
  
    return (
      <section className={`${theme.textNormal} ${theme.background} body-font overflow-hidden`}>
        <div className="container px-5 py-24 mx-auto">
          <div className="lg:w-4/5 mx-auto">
            <h1 class={`text-3xl font-medium title-font ${theme.textFocus} mb-12 text-center`}>{title}</h1>
            {faqs.map((faq, index) => (
              <div key={index} className="mb-4">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFAQ(index)}
                >
                  <span className="text-lg font-medium">{faq.question}</span>
                  <span className={`text-brand-400 text-${theme.themeColour}-400 text-2xl font-bold`}>
                    {activeIndex === index ? '-' : '+'}
                  </span>
                </div>
                <div
                className={`mt-2 transition-opacity duration-300 ${
                  activeIndex === index ? 'opacity-100 max-h-96' : 'opacity-0 max-h-0'
                }`}
              >
                {activeIndex === index && (
                  <p>{faq.answer}</p>
                )}
              </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  