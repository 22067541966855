import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import { TestimonialComponent } from "../../components/TestimonialsComponent";
import { FAQComponent } from "../../components/FAQComponent";
import ErrorPage from "../errorPage/ErrorPage";
import { set } from "lscache";
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";

const getProgram = (programDisplayId, appContext) => {
    return appContext.programsPageData.programsSection.programs.find(program => program.displayId === programDisplayId);
}

export default function ProgramPage() {
    const appContext = React.useContext(AppContext);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];
    const { programDisplayId, variantDisplayId } = useParams();
    const navigate = useNavigate();
    const [variant, setVariant] = React.useState(getProgram(programDisplayId, appContext)?.variants.find(variant => variant.displayId === variantDisplayId));
    const [program, setProgram] = React.useState({
        ...getProgram(programDisplayId, appContext),
        ...variant
    });
    const [variantsConfiguration, setVariantsConfiguration] = React.useState([]);

    console.log(program);
    const updateCurrentVariant = (variant) => {
        setVariant(variant);
        setProgram({
            ...getProgram(programDisplayId, appContext),
            ...variant
        });
        navigate(`/programs/${programDisplayId}/${variant.displayId}`);
    }

    useEffect(() => {
        if (!variant || !program) {
            navigate("/error");
        } else {
            setVariantsConfiguration(appContext.programsPageData.programsSection.variantsConfiguration.filter(variantConfiguration => {
                return variant[variantConfiguration.key] || variant.auxiliaryData[variantConfiguration.key];
            }))
        };
    }, [programDisplayId, variantDisplayId]);

    return (
        !variant || !program ? <ErrorPage /> :
            <>
                <section class={`${theme.textNormal} ${theme.background} body-font overflow-hidden`}>
                    <div class="container px-5 py-24 mx-auto">
                        <div class="lg:w-4/5 mx-auto flex flex-wrap">
                            <img alt="ecommerce" class="lg:w-1/2 w-full h-full h-64 object-cover object-center rounded lg:order-2" src={program.images[0]} />
                            <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mb-6 mt-6 lg:mb-0 lg:order-1">
                                <h2 key={`${variant.id}_description_summary`} class="text-sm title-font tracking-widest animate-fade-in">{program.descriptionSummary}</h2>
                                <h1 key={`${variant.id}_name`} class={`${theme.textFocus} text-3xl title-font font-medium mb-4 animate-fade-in`}>{program.name}</h1>
                                <div class="flex mb-4">
                                    <button class={`flex-grow border-b-2 text-brand-400 border-brand-500 py-2 text-lg px-1`} >Description</button>
                                </div>
                                <p key={`${variant.id}_description`} class="leading-relaxed mb-4 animate-fade-in">{program.description}</p>
                                {
                                    program.bulletPoints.map((bulletPoint, bulletPointIndex) => {
                                        return (
                                            <div class="flex border-gray-800 py-2 animate-fade-in" key={`${variant.id}_${bulletPointIndex}`}>
                                                <li><span class={`${theme.textFocus}`}>{bulletPoint}</span></li>
                                            </div>
                                        );
                                    })
                                }
                                <div class="flex flex-col py-4 xl:flex-row xl:items-center">
                                    {
                                        program.noPriceDisplayText ?
                                            (<span class="mb-2 xl:mb-0">
                                                <a class={`flex mt-2 xl:ml-auto xl:mt-0 text-white bg-brand-500 border-0 py-2 px-6 focus:outline-none hover:bg-brand-600 rounded`} href={`${program.auxiliaryData.noPriceDisplayTextLink}`}>{program.noPriceDisplayText}</a>
                                            </span>)
                                            :
                                            (<span class={`title-font font-medium text-2xl ${theme.textFocus} mb-2 xl:mb-0`}>
                                                ₹{program.price} {program.auxiliaryData.costSuffix}
                                            </span>)
                                    }
                                    <a class={`flex mt-2 xl:ml-auto xl:mt-0 text-white bg-brand-500 border-0 py-2 px-6 focus:outline-none hover:bg-brand-600 rounded`} href={`${program.auxiliaryData.registrationLink}`}>Register Now</a>
                                </div>
                                <div class="w-full">
                                    {variantsConfiguration.map((variantConfiguration, index) => (
                                        <div key={index} className={`flex flex-wrap`}>
                                            <span
                                                className={`flex-1 py-2 px-4 flex items-center font-medium`}
                                            >
                                                {variantConfiguration.title}
                                            </span>
                                            <span className={`flex-1 py-2 px-4 flex items-center justify-center`}
                                            >
                                                {program[variantConfiguration.key] || program.auxiliaryData[variantConfiguration.key]}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {program.variants.length > 1 && (<div class="lg:w-4/5 mx-auto mt-4">
                            <h1 class={`sm:text-3xl text-2xl font-medium title-font mb-2 ${theme.textFocus}`}>Select variant</h1>
                            <div class="lg:pr-10 lg:py-6 mb-6 lg:mb-0">
                                <div class="flex flex-wrap -m-4">
                                    {program.variants.map((currVariant, currVariantIndex) => (
                                        <div class={`xl:w-1/6 md:w-1/3 p-4`} onClick={() => { updateCurrentVariant(currVariant) }}>
                                            <div class={`${currVariant.displayId === variantDisplayId ? `${theme.backgroundTertiary} border border-brand-500` : theme.backgroundSecondary} p-6 rounded-lg hover:${theme.backgroundTertiary}`}>
                                                <h3 class="tracking-widest text-brand-500 text-xs font-medium title-font">{currVariant.numberOfClasses || currVariant?.auxiliaryData?.numberOfClasses}</h3>
                                                <h2 class="text-lg font-medium title-font mb-4">{currVariant.duration || currVariant?.auxiliaryData?.duration}</h2>
                                                <p class="leading-relaxed text-base">₹ {currVariant.price || currVariant?.auxiliaryData?.price}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>)}
                    </div>
                </section>
                {program.testimonials?.length ? TestimonialComponent(program.testimonials, "Testimonials"): ""}
                {program.faqs?.length ? FAQComponent(program.faqs, "FAQs"): ""}
            </>
    );
}     