import { SpeakerphoneIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import React from 'react';
import {AppContext} from '../../contexts/AppContext';
import lscache from 'lscache';
import { daysToMilliseconds } from '../../utils/dateUtils';

function Banner () {
  const IS_BANNER_CLOSED_CACHE_KEY = 'isBannerClosed';
  const [isClosed, setIsClosed] = useState(lscache.get(IS_BANNER_CLOSED_CACHE_KEY));
  const appContext = React.useContext(AppContext);

  useEffect(() => {
    lscache.set(IS_BANNER_CLOSED_CACHE_KEY, isClosed, daysToMilliseconds(7));
  }, [isClosed]);

  return (
    <>
      {
        !isClosed && appContext.bannerData &&
        <div className="bg-brand-600">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between flex-wrap">
              <div className="w-0 flex-1 flex items-center">
                <span className="flex p-2 rounded-lg bg-brand-800">
                  <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </span>
                <p className="ml-3 font-medium text-white truncate">
                  <span className="md:hidden">{appContext.bannerData.mobileTitle}</span>
                  <span className="hidden md:inline">{appContext.bannerData.desktopTitle}</span>
                </p>
              </div>
              <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                <Link
                  to={appContext.bannerData.linkTo}
                  className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-brand-600 bg-white hover:bg-brand-50"
                  target="_blank"
                >
                  {appContext.bannerData.linkText}
                </Link>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  className="-mr-1 flex p-2 rounded-md hover:bg-brand-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                  onClick={() => setIsClosed(true)}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Banner;