import React from "react";
import { AppContext } from "../../contexts/AppContext";
import { set } from "lscache";
import { ThemeContext, ThemeContextData } from "../../contexts/ThemeContext";

export default function TermsAndConditionsPage() {
    const appContext = React.useContext(AppContext);
    const themeContext = React.useContext(ThemeContext);
    const theme = ThemeContextData[themeContext];  

    // Added for hash based routing which is used for linking to specific sections of the page
    function updateScrollPosition() {
        var hash = window.location.hash;
        if (hash) {
          var element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        } else {
         window.scrollTo(0, 0);
        }
    }
    setTimeout(updateScrollPosition, 1000);

    return (
        <section class={`${theme.textNormal} ${theme.background} body-font`}>
            <div class="container px-5 py-5 mx-auto">
                <div class="text-center mb-10">
                    <h1 class={`sm:text-3xl text-2xl font-medium text-center title-font mb-4 ${theme.textFocus}`} id="terms-and-conditions">{appContext.termsAndConditionsPageData.termsAndConditionsSection.title}</h1>
                    <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">{appContext.termsAndConditionsPageData.termsAndConditionsSection.description}</p>
                </div>
                <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                    {
                        appContext.termsAndConditionsPageData.termsAndConditionsSection.termsAndConditions.map(termsAndCondition => {
                            return (
                                <div class="p-2 sm:w-1/2 w-full">
                                    <div class={`${theme.backgroundSecondary} rounded flex p-4 h-full items-center`}>
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-brand-400 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                            <path d="M22 4L12 14.01l-3-3"></path>
                                        </svg>
                                        <span class="title-font font-medium">{termsAndCondition}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div class="container px-5 py-5 mx-auto">
                <div class="text-center mb-10">
                    <h1 class={`sm:text-3xl text-2xl font-medium text-center title-font mb-4 ${theme.textFocus}`} id="refund-policy">{appContext.termsAndConditionsPageData.refundPolicySection.title}</h1>
                    <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">{appContext.termsAndConditionsPageData.refundPolicySection.description}</p>
                </div>
                <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                    {
                        appContext.termsAndConditionsPageData.refundPolicySection.refundPolicy.map(refundPolicy => {
                            return (
                                <div class="p-2 sm:w-1/2 w-full">
                                    <div class={`${theme.backgroundSecondary} rounded flex p-4 h-full items-center`}>
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-brand-400 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                            <path d="M22 4L12 14.01l-3-3"></path>
                                        </svg>
                                        <span class="title-font font-medium">{refundPolicy}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div class="container px-5 pb-24 mx-auto">
                <div class="text-center mb-10">
                    <h1 class={`sm:text-3xl text-2xl font-medium text-center title-font mb-4 ${theme.textFocus}`} id="privacy-policy">{appContext.termsAndConditionsPageData.privacyPolicySection.title}</h1>
                    <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">{appContext.termsAndConditionsPageData.privacyPolicySection.description}</p>
                </div>
                <div class="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                    {
                        appContext.termsAndConditionsPageData.privacyPolicySection.privacyPolicy.map(privacyPolicy => {
                            return (
                                <div class="p-2 sm:w-1/2 w-full">
                                    <div class={`${theme.backgroundSecondary} rounded flex p-4 h-full items-center`}>
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" class="text-brand-400 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 24 24">
                                            <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                                            <path d="M22 4L12 14.01l-3-3"></path>
                                        </svg>
                                        <span class="title-font font-medium">{privacyPolicy}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    );

}